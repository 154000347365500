<template>
  <div :class="`flex items-center space-x-2 ${params.styleClasses}`">
    <img v-if="params.value" :class="imageClasses" :src="params.value" />
    <div v-else class="text-gray-100">-</div>
    <span v-if="params.description" class="block truncate text-gray-100">{{ params.description }}</span>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  params: Object
});

const imageClasses = computed(() => {
  return props.params.imageClasses ? props.params.imageClasses : 'w-5 h-5';
});
</script>
